import { Component, Prop } from 'vue-property-decorator';
import { Button, Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { Prefetch, PrefetchComponent } from '~/mixins/prefetch';
import { ThemeColors } from '~/utils/theme';
import { VueComponentMixin } from '~/utils/vue-component';
import { CztWidgets } from '~/utils/views/widgets';

import { getModule } from 'vuex-module-decorators';
import RouterModule from '~/app/core/store/modules/RouterModule';
import { isTradeOffersWidget } from '~/app/core/apiClient';
import { PageLoader } from '~/components/templates/common';
import { createTradeOfferItem } from '~/utils/views/components';
import ListingItem from '~/components/molecules/listingItem/ListingItem';
import TradeOffersListFilter, {
  FilterEmit,
  TradeOffersListFilterOptions,
} from '../../tradeOffers/TradeOffersListFilter';
import UserModule from '~/app/core/store/modules/UserModule';
import { Link } from '~/components/molecules';
import {
  TradeOffersListInterface,
  TradeOffersListItem,
  TradeOffersRequestQuery,
} from './types';

const rootClass = 'czt-trade-offers-list';

@Component
export default class TradeOffersList
  extends VueComponentMixin<TradeOffersListInterface, PrefetchComponent>(
    Prefetch
  )
  implements TradeOffersListInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: TradeOffersListItem[];

  @Prop({ required: true, type: String })
  public title!: string;

  @Prop({ required: true, type: String })
  public guid!: string;

  @Prop({ required: true })
  public filterOptions!: TradeOffersListFilterOptions;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ type: String })
  public link?: string;

  @Prop()
  public overlayColor?: ThemeColors;

  @Prop({ default: 0, type: Number })
  public maxItems!: number;

  /**
   * This is the number of items in the next page that came with initial request
   */
  @Prop({ default: 0, type: Number })
  public nextPageCount!: number;

  @Prop({ default: 0, type: Number })
  public pageSize!: number;

  @Prop({ type: String })
  public buttonText?: string;

  public className = CztWidgets.TRADE_OFFFERS;

  /**
   * Extra property for storage of items loaded through load more button
   * as we cannot update the parent 'items' prop
   *
   * These items are concatenated in itemsCollection getter
   */
  protected loadedItems: TradeOffersListItem[] = [];

  /**
   * We are starting on page 1
   */
  protected pageNumber: number = 1;

  /**
   * Flag indicating we are downloading items from the backend
   */
  protected fetchingItems: boolean = false;

  /**
   * Whether it is possible to load more items in the eventtia
   */
  protected nextPage: boolean = true;

  protected filterData: FilterEmit = {
    representations: [],
    languages: [],
  };

  protected get routerModule(): RouterModule {
    return getModule(RouterModule, this.$store);
  }

  protected get userModule(): UserModule {
    return getModule(UserModule, this.$store);
  }

  public created() {
    if (this.nextPageCount === 0) {
      this.nextPage = false;
    }

    this.loadedItems = this.items;
  }

  public render() {
    return <v-sheet class={rootClass}>{this.renderContent()}</v-sheet>;
  }

  protected renderContent(): JSX.Element {
    const containerClasses = ['czt-spacer'];

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container id={this.anchorId} class={containerClasses.join(' ')}>
        <div class='pt-3 pb-0 px-0'>
          <v-row>{this.getHeadline()}</v-row>
          <TradeOffersListFilter
            onFilter={this.filter}
            loading={this.fetchingItems}
            filterOptions={this.filterOptions}
          />
          <v-row class='ma-0 pa-0'>
            <v-col class='py-0'>
              <v-row class={`${rootClass}__container`}>
                {this.getTradeOfferItems()}
              </v-row>
              {this.renderLoadMoreButton()}
            </v-col>
            {!this.userModule.user && (
              <v-col cols='12' class='text-center'>
                <i18n tag='div' path='app.tradeOffers.loginPrompt'>
                  <Link url='~/login'>{this.$t('app.tradeOffers.logIn')}</Link>
                </i18n>
              </v-col>
            )}
          </v-row>
        </div>
      </v-container>
    );
  }

  protected getHeadline(): JSX.Element | undefined {
    if (this.items.length === 0) {
      return;
    }

    return (
      <v-col class='py-0'>
        <Headline underscore align={Align.LEFT} level={2}>
          {this.title}
        </Headline>
      </v-col>
    );
  }

  protected getTradeOfferItems(): JSX.Element | JSX.Element[] {
    if (this.loadedItems.length < 1) {
      if (this.fetchingItems) {
        return (
          <v-col cols='12'>
            <PageLoader />
          </v-col>
        );
      }

      return (
        <v-col cols='12'>
          <Headline align={Align.CENTER} level={3}>
            {this.$t('app.search.noResults')}
          </Headline>
        </v-col>
      );
    }

    return this.loadedItems.map((item) => (
      <ListingItem key={`${item.id}__trade-offers__item`} item={item} />
    ));
  }

  protected renderLoadMoreButton(): JSX.Element | undefined {
    if (!this.nextPage) {
      // Do not render the load more button if paging is disabled or if there is no next page
      return;
    }

    return (
      <v-row no-gutters justify='center' class='py-3'>
        <Button
          disabled={!this.nextPage}
          loading={this.fetchingItems}
          onClick={() => {
            if (this.fetchingItems) {
              return;
            }

            this.pageNumber++;
            this.getItems();
          }}
        >
          {this.$t('app.common.loadMore')}
        </Button>
      </v-row>
    );
  }

  protected filter(data: FilterEmit): void {
    new Promise((resolve) => {
      this.filterData.representations = data.representations;
      this.filterData.languages = data.languages;
      this.loadedItems = [];
      this.pageNumber = 1;
      this.nextPage = false;
      resolve(undefined);
    }).then(this.getItems);
  }

  protected getItems(): void {
    this.fetchingItems = true;
    const query: TradeOffersRequestQuery = {};

    if (this.filterData.representations.length > 0) {
      query.foreignReps = this.filterData.representations.join('|');
    }
    if (this.filterData.languages.length > 0) {
      query.languageCodes = this.filterData.languages.join('|');
    }

    this.$api
      .widgets()
      .widgetsGetWidgetByGuid(
        this.guid,
        this.pageNumber,
        this.routerModule.resource
          ? this.routerModule.resource.guid
          : undefined,
        this.$i18n.locale,
        CztWidgets.TRADE_OFFFERS,
        undefined,
        undefined,
        this.userModule.token !== ''
          ? `Bearer ${this.userModule.token}`
          : undefined,
        {
          query,
        }
      )
      .then((widget) => {
        if (isTradeOffersWidget(widget)) {
          if (widget.items && widget.items.length > 0) {
            widget.items.forEach((item) => {
              this.loadedItems.push(createTradeOfferItem(item));
            });

            if (
              !widget.pageCount ||
              !widget.pageNum ||
              widget.pageCount <= widget.pageNum
            ) {
              this.nextPage = false;
            } else {
              this.nextPage = true;
            }
          } else {
            this.nextPage = false;
          }
        }
      })
      .catch(() => {
        this.pageNumber--;
      })
      .finally(() => {
        this.fetchingItems = false;
      });
  }
}
